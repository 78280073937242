<template>
  <div>
    <v-row>
      <v-col md="6" lg="5" sm="12">
        <s-crud
          noSearch
          noFooter
          :config="this.config"
          @save="saveDef($event)"
          add
          edit
          remove
          title="Platos"
          @rowSelected="selectMlt($event)"
        >
          <template scope="props">
            <v-container>
              <v-row>
                <v-col
                  ><s-select-definition
                    :def="1065"
                    label="Selecciona Tipo"
                    v-model="props.item.TypeMeal"
                  ></s-select-definition
                ></v-col>
              </v-row>
              <v-row>
                <v-col xs="12">
                  <s-text
                    v-model="props.item.MltPriceDefault" decimal
                    label="Precio"
                  ></s-text
                ></v-col>
                <v-col xs="12">
                  <s-text
                    v-model="props.item.MltDiscountDefault"
                    label="Descuento" decimal
                  ></s-text
                ></v-col>
              </v-row>
              <v-row>
                <v-col sm="4"
                  ><s-time
                    v-model="props.item.MltBeginHour"
                    label="Hora de Inicio"
                  ></s-time>
                </v-col>
                <v-col sm="4"
                  ><s-time
                    v-model="props.item.MltEndHour"
                    label="Hora de Fin"
                  ></s-time>
                </v-col>
                   <v-col xs="4">
                  <s-text max="1" number
                    v-model="props.item.MltBeforeHoursCancel"
                    label="Horas Cancelación"
                  ></s-text
                ></v-col>
              </v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
      <v-col md="6" lg="4" sm="12">
        <s-crud
          noSearch
          title="Tarifas"
          :filter="filter"
          v-if="filter != null"
          :config="this.configRate"
          @save="saveRate($event)"
          add
          edit
          remove
          noFooter
        >
          <template scope="props">
            <v-col>
              <v-row>
                <v-col
                  ><s-select-generic
                    itemtext="MntName"
                    itemvalue="MntID"
                    id="MntID"
                    v-model="props.item.MntID"
                    :config="configMenuSelect"
                  ></s-select-generic
                ></v-col>
              </v-row>
              <v-row
                ><v-col
                  ><s-text
                    decimal
                    label="Precio"
                    v-model="props.item.MlrPrice"
                  ></s-text
                ></v-col>
                <v-col
                  ><s-text
                    label="Descuento" decimal
                    v-model="props.item.MrlDiscount"
                  ></s-text></v-col
              ></v-row>
              <v-row
                ><v-col
                  ><s-date
                    v-model="props.item.MlrValidityDate"
                    label="Fecha Inicio"
                  ></s-date> </v-col
              ></v-row>
            </v-col>
          </template>
        </s-crud>
      </v-col>
      <v-col md="6" lg="3" sm="12">
        <s-crud
          noSearch
          title="Contenido"
          v-if="filter != null"
          :config="this.configDetail"
          @save="saveDetail($event)"
          noFooter
          :filter="filter"
          add
          remove
          
        >
          <template scope="props">
            <v-col>
              <s-select-definition
                :def="1079"
                v-model="props.item.TypeContent"
                label="Tipo de Contenido"
              ></s-select-definition>
            </v-col>
          </template>
        </s-crud>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Service from "./../../services/DinningHall/MealTypeService";
import TypeDetailService from "./../../services/DinningHall/MealTypeDetailService.";
import RateService from "./../../services/DinningHall/MealRateService";
import MenuService from "./../../services/DinningHall/MenuTypeService";
export default {
  name: "MealType",
  components: {},

  data: () => ({
    disabledRate: false,
    configMenuSelect: {
      url: MenuService.getUrllist(),
      params: {},
      title: "Tipo de Menú",
    },
    parentID: null,
    filter: null,
    config: {
      model: {
        TypeMeal: "int",
        MltID: "ID",
        MltBeginHour: "time",
        MltEndHour: "time",
        MltPriceDefault: "int",
        MltDiscountDefault: "int",
        SecStatus: "booleam",
        MltBeforeHoursCancel:"int",
      },
      title: "PLATOS COMEDOR",
      service: Service,
      headers: [
        { text: "Plato", value: "TypeMealText", sortable: false },
        { text: "Inicio", value: "MltBeginHour", sortable: false },
        { text: "Fin", value: "MltEndHour", sortable: false },
        { text: "Precio", value: "MltPriceDefault", sortable: false },
        { text: "Desc", value: "MltDiscountDefault", sortable: false },
      ],
    },
    configRate: {
      model: {
        MlrID: "ID",
        MltID: "ID",
        MntID: "ID",
        MlrValidityDate: "date",
        MlrPrice: "int",
        MrlDiscount: "int",
        SecStatus: "booleam",
      },
      title: "PLATOS COMEDOR",
      service: RateService,
      headers: [
        { text: "Tipo", value: "TypeMenuText", sortable: false },
        { text: "Precio", value: "MlrPrice", sortable: false },
        { text: "Desc", value: "MrlDiscount", sortable: false },
        { text: "Inicio", value: "MlrValidityDate", sortable: false },
      ],
    },
    configDetail: {
      model: {
        TypeContent: "int",
        MtdID: "ID",
        MltID: "int",
        SecStatus: "booleam",
      },
      title: "PLATOS COMEDOR",
      service: TypeDetailService,
      headers: [
        { text: "ID", value: "MtdID", sortable: false },
        {
          text: "Tipo de Contenido",
          value: "TypeContentText",
          sortable: false,
        },
      ],
    },
  }),
  mounted() {
    this.configMenuSelect.url = MenuService.getUrllist();
  },

  methods: {
    selectMlt(items) {
      if (items.length > 0) {
        this.parentID = items[0].MltID;
        this.filter = { MltID: this.parentID };
      }
    },
    saveDef(item) {
      if (item.TypeMeal == 0) {
        this.$fun.alert("Eliga Tipo de Plato", "warning");
        return;
      }
      //  if(!this.$fun.IsValidDecimal(item.MltPriceDefault) ){ this.$fun.alert('Precio Incorrecto','warning');return;};
      // if(!this.$fun.IsValidDecimal(item.MltDiscountDefault)){ this.$fun.alert('Descuento Incorrecto','warning');return;};
      item.save();
    },
    saveRate(item) {
      if (item.MntID == 0) {
        this.$fun.alert("Eliga Tipo de Menu", "warning");
        return;
      }
      item.MltID = this.parentID;
      item.save();
    },
    saveDetail(item) {
      if (item.TypeContent == 0) {
        this.$fun.alert("Eliga Tipo de Contenido", "warning");
        return;
      }
      item.MltID = this.parentID;
      console.log(item);
      item.save();
    },
  },
};
</script>
